import { Box, Button, Center, CircularProgress, Stack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link as RLink, Outlet, useNavigate } from "react-router-dom";
import { HeaderSection } from "../../components/Ui/HeaderSection";
import { EstateService } from "../../services/EstateService";
import { EstateTable } from "./components/EstateTable";

export const Estates = () => {
  const { data, status } = useQuery(["estates"], EstateService.getEstates);
  const navigate = useNavigate();

  return (
    <>
      <Stack spacing={4}>
      <HeaderSection title="List of Estates">
        <Button as={RLink} to={"/new-estate"} colorScheme="green">
          New Estate
        </Button>
      </HeaderSection>
      {status === "loading" ? (
        <Center>
          <CircularProgress
            value={80}
            thickness={8}
            isIndeterminate
            color="purple.300"
          />
        </Center>
      ) : (
        <Box>
          <EstateTable estates={data || []} />
        </Box>
      )}
      </Stack>
      <Outlet />
    </>
  );
};
