import { Box, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const Logo = (props: any) => {
  return (
    <Box {...props}>
      <Link to={"/"}>
        <Image src="../../assets/logo.png" width={100} />
      </Link>
    </Box>
  );
};
