import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { OnBoardingFormType } from "Maigadi";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { EstateService } from "../../services/EstateService";

const validationSchema = Yup.object({
  name: Yup.string().required().label("Estate Name"),
  contactFullName: Yup.string().required().label("Contact's name"),
  contactEmail: Yup.string().email().required().label("Contact's email"),
  contactPhoneNumber: Yup.string().min(11).required().label("Contact's phone"),
  address: Yup.string().required().label("Address"),
  numberOfUnits: Yup.number().required().label("Number of unit"),
  state: Yup.string().required().label("State"),
  country: Yup.string().required().label("Country"),
});

export const EditEstateForm = () => {
  const { mutate, status } = useMutation(EstateService.onBoardEstate);
  const { id } = useParams<{ id: string }>();

  let { status: loadingStatus, data } = useQuery(
    ["estate", id],
    async () => {
      const estate = await EstateService.getEstate(id!);

      return {
        ...estate,
        contactEmail: estate.users![0].email,
        contactFullName: `${estate.users![0].firstname} ${estate.users![0].lastname}`,
        contactPhoneNumber: estate.users![0].phone,
      } as OnBoardingFormType
    },
  );

  const navigate = useNavigate();

  const { handleSubmit, handleChange, errors, values } = useFormik<
    OnBoardingFormType>
  ({
    initialValues: data || {} as OnBoardingFormType,
    validationSchema,
    enableReinitialize: true,
    onSubmit(value) {
      mutate(value, {
        onSuccess(data) {
          navigate("/estates");
        },
        onError(error) {
          console.log(error);
        },
      });
    },
  });

  if (loadingStatus === "loading" || values === undefined) {
    return (
      <Center>
        <Spinner color="purple" size={"xl"} />
      </Center>
    );
  }

  return (
    <Box width="md" m={"auto"}>
      <Heading as="h4" size={"md"} alignSelf="flex-start" pb="4" pt="4">
        Estate On Boarding Form
      </Heading>
      <Divider color={"purple"} />
      <Box pt="4">
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>What is the estate's name?</FormLabel>
              <Input
                onChange={handleChange}
                name="name"
                value={values.name}
                size="md"
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>What is the estate contact's full name?</FormLabel>
              <Input
                onChange={handleChange}
                name="contactFullName"
                value={values.contactFullName}
                size="md"
              />
              <FormErrorMessage>{errors.contactFullName}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>How many units?</FormLabel>
              <Input
                min={20}
                max={1000000}
                value={values.numberOfUnits}
                onChange={handleChange}
                name="numberOfUnits"
                type="number"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>How much is each unit to pay?</FormLabel>
              <Input
                min={0}
                value={values.pricePerUnit}
                onChange={handleChange}
                name="pricePerUnit"
                type="number"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>What is the contact's phone number?</FormLabel>
              <Input
                onChange={handleChange}
                name="contactPhoneNumber"
                value={values.contactPhoneNumber}
                size="md"
              />
              <FormErrorMessage>{errors.contactEmail}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>What is the contact's email?</FormLabel>
              <Input
                onChange={handleChange}
                name="contactEmail"
                value={values.contactEmail}
                size="md"
              />
              <FormErrorMessage>{errors.contactEmail}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Where is the estate located?</FormLabel>
              <Textarea noOfLines={3} name="address" onChange={handleChange} value={values.address}/>
              <FormErrorMessage>{errors.address}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>What state is the estate located in?</FormLabel>
              <Select
                size={"sm"}
                placeholder="Select state"
                onChange={handleChange}
                value={values.state}
                name="state"
              >
                <option value={"Abuja"}>Abuja</option>
                <option value={"Lagos"}>Lagos</option>
                <FormErrorMessage>{errors.state}</FormErrorMessage>
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>What country is the estate located in?</FormLabel>
              <Select
                size={"sm"}
                placeholder="Select state"
                value={values.country}
                onChange={handleChange}
                name="country"
              >
                <option value={"Nigeria"}>Nigeria</option>
                <FormErrorMessage>{errors.state}</FormErrorMessage>
              </Select>
            </FormControl>

            <FormControl>
              <Button
                variant={"solid"}
                colorScheme="purple"
                width={"100%"}
                isLoading={status === "loading"}
                type="submit"
              >
                On Board Estate
              </Button>
            </FormControl>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};
