import { Heading, Stack, Button, Box, Divider } from "@chakra-ui/react"
import { PropsWithChildren } from "react"

interface Props extends PropsWithChildren {
  title: string;
}

export const HeaderSection = ({children, title}: Props) => {
  return <>
    <Stack direction={"row"} justifyContent="space-between" mt="4">
        <Stack>
          <Heading display={"flex"} as="h5">
            {title}
          </Heading>
        </Stack>
        <Stack>
          <Box display={"flex"}>
            {children}
          </Box>
        </Stack>
      </Stack>
      <Divider orientation="horizontal" />
  </>
}