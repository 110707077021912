import {
    Box,
    Button,
    Center,
    Heading,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spinner,
    Stack,
    Text
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FaChevronDown, FaEnvelope, FaPhone, FaUser } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HeaderSection } from "../../components/Ui/HeaderSection";
import { EstateService } from "../../services/EstateService";

export const EstateDetail = () => {
  const { id } = useParams<{ id: string }>();

  const { data, status } = useQuery(["estates", id], () =>
    EstateService.getEstate(id!)
  );

  const navigate = useNavigate();

  if (status === "loading") {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Modal
      isOpen={true}
      onClose={() => navigate("/estates", { replace: true })}
      colorScheme="whiteAlpha"
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box mt="8">
            <HeaderSection title={data?.name!}>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<FaChevronDown />}
                  bgColor={"purple"}
                  color="white"
                  _hover={{ color: "white", backgroundColor: "purple" }}
                  _active={{ color: "white", backgroundColor: "purple" }}
                >
                  Actions
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={`/edit-estate/${id}`}
                    _hover={{ color: "white", backgroundColor: "purple" }}
                    fontSize="sm"
                    fontWeight={"normal"}
                  >
                    Edit
                  </MenuItem>
                  {/* <MenuItem
                    _hover={{ color: "white", backgroundColor: "purple" }}
                    fontSize="sm"
                    fontWeight={"normal"}
                  >
                    Deactivate Account
                  </MenuItem>
                  <MenuItem
                    _hover={{ color: "white", backgroundColor: "purple" }}
                    fontSize="sm"
                    fontWeight={"normal"}
                  >
                    Activate Account
                  </MenuItem> */}
                </MenuList>
              </Menu>
            </HeaderSection>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <SimpleGrid columns={3} spacing={10}>
              <Box>
                <Heading size={"md"} color={"purple"}>
                  Address
                </Heading>
                <Text as={"span"}>
                  {data?.address}
                  <br />
                  {data?.state}
                  <br />
                  {data?.country}
                </Text>
              </Box>
              <Box>
                <Heading size="md" color={"purple"}>Number of Units</Heading>
                <Text>{data?.numberOfUnits}</Text>
              </Box>
              <Box>
                <Heading size={"md"} color={"purple"}>Price Per Unit</Heading>
                <Text>{data?.pricePerUnit}</Text>
              </Box>
            </SimpleGrid>
            <Stack>
              <Heading size={"md"} color={"purple"}>
                Contact Person
              </Heading>
              <Stack direction={"row"} alignItems={"center"}>
                <FaUser />
                <Text verticalAlign={"center"}>
                  {data?.users![0].firstname} {data?.users![0].lastname}
                </Text>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <FaPhone />
                <Text verticalAlign={"center"}>{data?.users![0].phone}</Text>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <FaEnvelope />
                <Text verticalAlign={"center"}>{data?.users![0].email}</Text>
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
