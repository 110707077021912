import { AxiosResponse } from "axios";
import { Estate, OnBoardingFormType } from "Maigadi";
import axios from "../app/axios";

export const EstateService = {
  async getEstates() {
    const response = await axios.get<AxiosResponse<Estate[]>>("/estates");

    return response.data.data;
  },

  async getEstate(id: string) {
    const response = await axios.get<AxiosResponse<Partial<Estate>>>(`/estates/${id}`);

    return response.data.data;
  },

  onBoardEstate(estate: Partial<OnBoardingFormType>) {
    return axios.post("/estates", estate);
  },

  updateEstate(estate: Partial<OnBoardingFormType>) {
    return axios.put("/estates", estate);
  }
};
