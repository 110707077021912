import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Button } from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa";
import { Link } from "react-router-dom";

type PropsType = {
  id: string;
};

export const DropdownMenu = ({ id }: PropsType) => {
  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<FaChevronDown />}>
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} to={`/estates/${id}`}>
          View
        </MenuItem>
        <MenuItem as={Link} to={`/edit-estate/${id}`}>
          Edit
        </MenuItem>
        {/* <MenuItem>Deactivate Account</MenuItem> */}
      </MenuList>
    </Menu>
  );
};
