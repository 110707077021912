import { Container } from "@chakra-ui/react";
import { useContext } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Header } from "../Sections/Header";

export const ProtectedRoute = () => {
  const userSession = useContext(AuthContext);
  const navigate = useNavigate();

  if (!userSession.getCurrentUser()) {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <>
      <Header />
      <Container maxW={"container.xl"}>
        <Outlet />
      </Container>
    </>
  );
};
