import React, { createContext } from "react";

const userSession = {
  getCurrentUser() {
    const token = sessionStorage.getItem("token");

    return token;
  },
  logoutUser() {
    sessionStorage.clear();
  },
  getUserProfile() {
    return JSON.parse(sessionStorage.getItem("userProfile")!);
  },
};

type UserSession = typeof userSession;

export const AuthContext = createContext<UserSession>({} as UserSession);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <AuthContext.Provider value={userSession}>{children}</AuthContext.Provider>
  );
};
