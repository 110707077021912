import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Link,
  Stack,
} from "@chakra-ui/react";
import { useContext } from "react";
import { Link as RLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Logo } from "../Ui/Logo";

export const Header = () => {
  const userSession = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    userSession.logoutUser();
    navigate("/login", {
      replace: true,
    });
  };

  return (
    <Container minW={"100vw"} bgColor={"gray.900"} pt={4} pb={4}>
      <Container minW={{ md: "container.xl" }}>
        <Flex justifyContent={"space-between"}>
          <HStack spacing={8}>
            <Box>
              <Logo />
            </Box>

            <HStack as="nav" alignContent={"end"} spacing={5}>
              <Link as={RLink} to="/" color={"white"}>
                Home
              </Link>
              <Link as={RLink} to="/estates" color={"white"}>
                Manage Estates
              </Link>
              <Link as={RLink} to="/subscription" color={"white"}>
                Manage Subscriptions
              </Link>
              <Link as={RLink} to="/users" color={"white"}>
                Manage Users
              </Link>
            </HStack>
          </HStack>

          <Stack justify={"flex-end"} alignSelf="center">
            <Button
              as={"a"}
              variant="outline"
              colorScheme={"red"}
              onClick={logout}
            >
              Logout
            </Button>
          </Stack>
        </Flex>
      </Container>
    </Container>
  );
};
