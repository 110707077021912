import {
  Alert,
  AlertIcon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Estate } from "Maigadi";
import { DropdownMenu } from "./DropdownMenu";

interface IProps {
  estates: Estate[];
}

export const EstateTable = ({ estates }: IProps) => {
  return (
    <>
      {estates.length > 0 ? (
        <TableContainer>
          <Table variant={"striped"} colorScheme="teal" maxW={"100%"}>
            <Thead>
              <Tr>
                <Th>S/N</Th>
                <Th>Name</Th>
                <Th>Contact Person</Th>
                <Th>Contact Phone</Th>
                <Th>Email</Th>
                <Th>Number of Units</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {estates.map((estate, index) => {
                return (
                  <Tr>
                    <Td>{++index}</Td>
                    <Td>{estate.name}</Td>
                    <Td>{`${estate.users[0].firstname} ${estate.users[0].lastname}`}</Td>
                    <Td>{estate.users[0].phone}</Td>
                    <Td>{estate.users[0].email}</Td>
                    <Td>{estate.numberOfUnits}</Td>
                    <Td><DropdownMenu id={estate.id!} /></Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Alert>
          <AlertIcon />
          No estate have been on-boarded
        </Alert>
      )}
    </>
  );
};
