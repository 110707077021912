import { Box, ChakraProvider, Text, useMediaQuery } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ProtectedRoute } from "./components/Layout/ProtectedLayout";
import { AuthProvider } from "./context/AuthContext";
import { Estates } from "./pages/Estates";
import { EditEstateForm } from "./pages/Estates/edit-form";
import { EstateForm } from "./pages/Estates/form";
import { EstateDetail } from "./pages/Estates/view";
import { Login } from "./pages/Login";

function App() {
  const queryClient = new QueryClient();

  const [isMobile] = useMediaQuery("(max-width: 400px)");

  if (isMobile) {
    return (
      <ChakraProvider>
        <Box alignContent={"center"} verticalAlign={"middle"}>
          <Text
            color={"purple.900"}
            fontWeight="bold"
            textAlign={"center"}
            fontSize="lg"
            as={"h1"}
          >
            There is no support for mobile.
          </Text>
        </Box>
      </ChakraProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<ProtectedRoute />}>
                <Route path="/estates" element={<Estates />}>
                  <Route path="/estates/:id" element={<EstateDetail />} />
                </Route>
                <Route path="/new-estate" element={<EstateForm />} />
                <Route path="/edit-estate/:id" element={<EditEstateForm />} />
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </Router>
        </AuthProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
