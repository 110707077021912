import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { config as globalConfig } from "./config";

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.baseURL = globalConfig.baseURL;

    const token = sessionStorage.getItem("token");

    if (token) {
      config.headers = {
        Authorization: token,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error: AxiosError) => {
    if (
      error.response?.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      return window.history.replaceState(null, "", "/login");
    }

    if (error.response?.status === 500) {
      return Promise.reject({
        message: "We can't process your request at the moment. Please try again."
      })
    }

    return Promise.reject(error.response?.data);
  }
);

export default axios;
