import { LoginResponse, UserCredential } from "Maigadi";
import axios from "../app/axios";

export const AuthService = {
  async login(userCredentials: UserCredential) {
    const response = await axios.post<LoginResponse>(
      "/users/backoffice-login",
      userCredentials
    );

    const currentUser = response.data.data;

    sessionStorage.setItem("token", currentUser.token!);
    sessionStorage.setItem("refreshToken", currentUser.refreshToken!);
    delete currentUser.refreshToken;
    delete currentUser.token;
    sessionStorage.setItem("currentUser", JSON.stringify(currentUser));

    return currentUser;
  },
};
