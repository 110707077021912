import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { HttpError } from "Maigadi";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/authService";

export const Login = () => {
  const { mutate, status, error, data } = useMutation(AuthService.login);
  const navigate = useNavigate();

  const { handleChange, handleSubmit } = useFormik({
    onSubmit: async (values) => {
      mutate(values, {
        onSuccess(data) {
          navigate("/");
        },
        onError(error) {
          console.log(error);
        },
      });
    },
    initialValues: {
      email: "",
      password: "",
    },
  });

  return (
    <Flex
      flexDirection={"column"}
      w="100vw"
      height="100vh"
      bgColor={"gray.200"}
      alignContent="center"
    >
      <Grid templateColumns={"repeat(2, 1fr)"} gap={6}>
        <GridItem w="100%" bgColor={"blue.500"} h={"100vh"} bgImage={require('../../assets/estate-gates.jpg')} bgSize="cover"></GridItem>
        <GridItem w="100%" justifyContent={"center"} alignContent={"center"}>
          <Center>
            <Stack>
              <Heading color={"blue.900"} textAlign="center">
                Welcome
              </Heading>
              <Box maxW="450px" minW="450px" padding={"8"} alignSelf={"center"} justifySelf={"center"}>
                <form onSubmit={handleSubmit}>
                  <Stack
                    spacing={4}
                    p={"1rem"}
                    boxShadow={"md"}
                    bgColor="white"
                  >
                    {status === "error" && (
                      <Alert status="error" maxW={"600px"}>
                        <AlertIcon />
                        <AlertDescription>
                          {error !== undefined
                            ? (error as HttpError).message
                            : "Something went wrong! Try again later."}
                        </AlertDescription>
                      </Alert>
                    )}
                    <FormControl>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input name="email" id="email" onChange={handleChange} />
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <Input
                        name="password"
                        id="password"
                        onChange={handleChange}
                        type="password"
                      />
                    </FormControl>

                    <FormControl>
                      <Button
                        type="submit"
                        variant={"solid"}
                        colorScheme="teal"
                        width={"full"}
                        size="md"
                        disabled={status === "loading"}
                        isLoading={status === "loading"}
                      >
                        Login
                      </Button>
                    </FormControl>
                  </Stack>
                </form>
              </Box>
            </Stack>
          </Center>
        </GridItem>
      </Grid>
    </Flex>
  );
};
